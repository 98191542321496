import * as React from "react";
import type { HeadFC } from "gatsby";
import { Header } from "../components/Header";
import { Splash } from "../components/Splash";
import { Faq } from "../components/Faq";
import { Coaches } from "../components/Coaches";
import { Socials } from "../components/Socials";
import "../styles/fontello.css";
import "../styles/main.scss";

const IndexPage = () => {
  return (
    <>
      <Header />
      <main className="Main">
        <Splash />
        <Faq />
        <Coaches />
      </main>
      <Socials />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Addiscombe Judo Club</title>
    <meta property="og:title" content="Addiscombe Judo Club" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://addiscombejudo.com" />
    <meta
      property="og:image"
      content="https://www.addiscombejudo.com/images/AddiscombeLogo.png"
    />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="256" />
    <meta property="og:image:height" content="256" />
    <meta
      name="description"
      property="og:description"
      content="Addiscombe Judo Club has been helping children of all ages to learn Judo since 2002"
    ></meta>
  </>
);
