import * as React from "react";

export const Splash = () => {
  return (
    <div className="HomeContainer">
      <div className="Background"></div>
      <h1>Welcome to Addiscombe Judo Club</h1>
      <p>
        Based in Addiscombe, Croydon - providing a fun atmosphere for under 18s
        to learn Judo since 2002.
      </p>
    </div>
  );
};
