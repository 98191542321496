import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

type Props = {
  image: IGatsbyImageData;
  name: string;
  description: string;
  role?: string;
  grade?: string;
  favouriteThrow?: string;
};

export const Coach = ({
  image,
  name,
  description,
  role,
  grade,
  favouriteThrow,
}: Props) => {
  return (
    <div className="TeamMember">
      <GatsbyImage image={image} alt={""} style={{ width: "fit-content" }} />
      <div className="Biography">
        <h4>{name}</h4>
        {grade && <h5>Grade: {grade}</h5>}
        {favouriteThrow && (
          <h5 className="MinorHeading">Favourite throw: {favouriteThrow}</h5>
        )}
        <p>{description}</p>
      </div>
    </div>
  );
};
