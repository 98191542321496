import * as React from "react";

export const Faq = () => {
  return (
    <div className="Container FAQ">
      <h3>FAQ:</h3>
      <ul>
        <li>
          <h5>So, what is Judo?</h5>
          <p>
            Judo is a martial art originating in Japan - focusing on throwing
            and grappling, it is a great sport for fitness, learning a new
            skill, and most importantly having fun! You may have seen some on
            the tv during the Olympics!
            <br/>
            Anyone can do Judo and we encourage you to come and give it a try!
          </p>
        </li>
        <li>
          <h5>When and where do you train?</h5>
          <p>
            We train at Beverley Hall, Grant Road, CRO 6PJ, Addiscombe on Saturday
            mornings. Our classes are as follows:
          </p>
          <p>
            <b>Under 9s:</b> 10:20-11:30
            <br />
            <b>Over 9s:</b> 11:35-12:50
          </p>
        </li>
        <li>
          <h5>What else do I need to know?</h5>
          <p>
            All of our instructors are experienced, First Aid trained, DBS
            checked and Qualified Coaches. We all believe that all sport should
            be fun and rewarding for everyone, and ensure that all of our
            students are supported and encouraged to enjoy their training and
            reach their full potential!
          </p>
        </li>
        <li>
          <h5>How do I find out more?</h5>
          <p>
            You can contact us on{" "}
            <b>
              <a href="https://www.facebook.com/Addiscombejudoclub">facebook</a>
            </b>
            , or email us at{" "}
            <b>
              <a href="mailto:addiscombejudo@gmail.com">
                addiscombejudo<wbr></wbr>@gmail.com
              </a>
            </b>{" "}
            <br />
            If you'd rather talk to us in person, feel free to come along on a
            Saturday morning.
          </p>
        </li>
      </ul>
    </div>
  );
};
