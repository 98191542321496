import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Coach } from "./Coach";

export const Coaches = () => {
  const x = useStaticQuery(query);
  console.log(x);
  return (
    <div className="Container">
      <h3>Our Coaches:</h3>
      <div className="Coaches">
        <p>
          All of the coaches at Addiscombe Judo Club are fully First Aid and
          Safeguarding Trained, DBS checked and are qualified BJA and NAKMAS
          coaches.
        </p>
        {x.allMdx.nodes.map((coach: any) => (
          <Coach
            key={coach.id}
            image={coach.frontmatter.image.childImageSharp.gatsbyImageData}
            name={coach.frontmatter.name}
            role={coach.frontmatter.role}
            grade={coach.frontmatter.grade}
            favouriteThrow={coach.frontmatter.favouriteThrow}
            description={coach.body}
          />
        ))}
      </div>
    </div>
  );
};

const query = graphql`
  query Coaches {
    allMdx(sort: { order: ASC, fields: frontmatter___order }) {
      nodes {
        frontmatter {
          name
          role
          grade
          favouriteThrow
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body
      }
    }
  }
`;
